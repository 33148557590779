<template>
  <div>
    <loading
      v-model:active="is_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <article class="message is-warning is-small" v-if="error_message">
      <div class="message-body">
        {{ error_message }}
      </div>
    </article>
    <div class="content">
      <ReceptionHeader>
        <PaymentActionFlow
          payment_status="done"
          select_status="active"
          post_status="active"
          complete_status="active"
        />
      </ReceptionHeader>
      <article class="message is-success is-small" v-if="success_message">
        <div class="message-body">
          {{ success_message }}
        </div>
      </article>
      <div v-if="is_allow_action">
        <div class="info">
          ご利用の事業者への発送は発払となります。<br />
          <p>料金&nbsp;&nbsp;{{ service.payment_price }}円</p>
          決済方法はクレジットカードのみとなります。<br />
          お手元にカードをご用意の上、商品を宅配ボックスへお持ちください。
        </div>
        <div class="btn-wrapper">
          <button
            class="button is-rounded is-medium btn-submit"
            @click="createPaymentReception"
            v-if="is_allow_action"
          >
            次へ
          </button>
        </div>
        発送しない方はブラウザを閉じてください。
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import GMOPaymentService from "../services/GMOPaymentService"
import ServiceRepository from "../repositories/ServiceRepository"
import { Auth } from "aws-amplify"
import Loading from "vue-loading-overlay"
import PaymentActionFlow from "../components/elements/PaymentActionFlow.vue"
import ReceptionHeader from "../components/ReceptionHeader.vue"

export default {
  name: "PaymentReception",
  data() {
    return {
      is_loading: false,
      is_allow_action: false,
      is_show_modal: false,
      error_message: "",
      success_message: "",
      service: {
        code: "",
        name: "",
        payment_price: "",
        is_payment_enabled: "",
      }
    }
  },
  components: {
    Loading,
    PaymentActionFlow,
    ReceptionHeader,
  },
  async beforeMount() {
    this.is_loading = true
    if (this.$route.query.sr === undefined || this.$route.query.trc === undefined) {
      this.error_message = "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }
    try {
      const service = await ServiceRepository.findByCode(this.$route.query.sr)
      if (!service.is_payment_enabled) {
        this.$router.push({
          path: "/reception",
          query: this.$route.query,
        })
      }
      this.service = service
      this.$store.commit("setService", service)
      this.is_allow_action = true
    } catch (error) {
      if (error.response) {
        if (error.response.status === 406) {
          this.error_message =
            "お客様がご利用のEC事業者様はスマリ宅配ボックス発送には対応しておりません。ローソン店舗をはじめとしたスマリボックスから発送をお願いいたします。"
          return
        }
      }
      this.error_message = "エラーが発生しました。しばらく時間をおいて、最初から操作を行ってください。"
    } finally {
      this.is_loading = false
    }
  },
  methods: {
    async createPaymentReception() {
      this.is_loading = true
      try {
        const values = {
          sr_code: this.$route.query.sr,
          service_trc_no: this.$route.query.trc,
          optional_service_no: this.$route.query.opt,
          service_name: this.service.name,
          amount: this.service.payment_price,
        }
        const reception = await DeliveryboxReceptionRepository.createWithPayment(values)
        this.$route.query.token = reception.token
        this.$router.push({
          path: "/payment/store/select",
          query: this.$route.query,
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.error_message = "既に発送受付処理が完了している商品です。ご確認ください。"
          return
        }
        this.error_message =
          "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      } finally {
        this.is_loading = false
      }
    },
    
  },
}
</script>

<style scoped>
.info p {
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.btn-wrapper {
  text-align: center;
  padding: 20px 0 50px;
}
.to-payment {
  padding: 80px 16px 0;
  box-sizing: border-box;
}
.to-payment p {
  color: white;
  font-weight: bold;
  margin-bottom: 12px;
}
.to-payment .img-wrap {
  width: 200px;
  margin: 0 auto 24px;
}
.modal-content {
  max-height: 100vh;
}
.btn-tolinkplus {
  font-weight: bold;
  width: 200px;
}
</style>
